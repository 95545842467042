import React,{useRef} from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import download from '../../../Assets/images/download.png'
import Receipt from './Receipt';
import './receipt.css'




export function numberInWords(price) {
  const sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
    dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
    tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
    handle_tens = function(dgt, prevDgt) {
      return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
    },
    handle_utlc = function(dgt, nxtDgt, denom) {
      return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
    };

  let str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    // eslint-disable-next-line prefer-const
    words = [];
  if (price += "", isNaN(parseInt(price))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
      case 0:
        words.push(handle_utlc(digit, nxtDigit, ""));
        break;
      case 1:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 2:
        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
        break;
      case 3:
        words.push(handle_utlc(digit, nxtDigit, "Thousand"));
        break;
      case 4:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 5:
        words.push(handle_utlc(digit, nxtDigit, "Lakh"));
        break;
      case 6:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 7:
        words.push(handle_utlc(digit, nxtDigit, "Crore"));
        break;
      case 8:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 9:
        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
    }
    str = words.reverse().join("")
  } else str = "";
  return str

}


const App1 = ({receipt,type}) => {
  const company = {
  name: type==="repayment"?"MONK CAPITAL PVT LTD":"Feepay",
  address: `The Platina, B-406, Sy No. 132, 134, H. No. 4-50/1, Gachibowli, K.V.Rangareddy, Seri Lingampally, Telangana, India, 500032`,
  iacgAddress:`Nandini Complex, Dilsukh Nagar Main Rd, beside Vijaya Diagnostic centre, Indira Nagar, Dilsukhnagar, Hyderabad, Telangana 500036`,
  ushodayaAddress:`Attapur, Rambagh Colony, Hyderabad, Telangana 500064`,
  joyAddress:`G9W4+8R9, Mallampet Rd, Bachupally, Hyderabad, Telangana 500090`,
  indicAddress:`SY No 116, Cine Planet, Lane, opp. Siddh Convention, Satyam Enclave, Kompally, Hyderabad, Telangana 500014`
};
const logo=window?.location?.href?.split('?')[1]?.split('&')[1]?.split('=')[1] || ""

  let user={}
  let receiptNo=''
  let date=''
  let fileName=''
  switch (type) {
    case "feepayment":   user = {
                          username: receipt.studentName, // Example username
                          LoanID: receipt.txnId, // Example LoanID
                          loanType:receipt.payment_name ,
                          amount:parseInt(receipt?.paid_amount),
                          paymentMethod: receipt.paymentMode,
                          bank:receipt.bankName?receipt?.bankName:"NA",
                          branch: receipt?.bankBranch?receipt?.bankBranch:"NA",
                          amountInWords:numberInWords(receipt.paid_amount) ,
                          dateOfPayment: receipt.paid_date, // Example date of payment
                        };

                          receiptNo = receipt.txnid;
                          date = receipt.paid_date;
                          fileName = `Receipt_${receipt.studentName}_${date}.pdf`;
      
      break;
    case "repayment": user = {
                          username: receipt.firstname, // Example username
                          amount:receipt?.repaidEmiAmount,
                          amountInWords: receipt.repaidEmiAmount,
                          emiNo:receipt.emiNo,
                          dateOfPayment: receipt.emiRepaidDate, // Example date of payment
                        };

                          receiptNo = receipt.emiId;
                          date = receipt.emiRepaidDate;
                          fileName = `Receipt_${receipt.firstname}_${date}.pdf`;
      
      break;
   
  
    default: user = {};
      break;
  }
 
 
  // const handleCloseTab=()=>{
  //   document.getElementById('pdfpage').className="clicked"
  //   setTimeout(() => {
  //     window.close()
      
  //   }, 2000);
  // }
  
  return(
  <>
    <PDFDownloadLink document={<Receipt company={company} user={user} receiptNo={receiptNo} date={date} logo={logo}/>} fileName={fileName}>
    {type==="feepayment"?<div style={{display:'flex',paddingTop:'1em'}}>
      <div><img style={{width:'20px',height:'20px',marginLeft:'1em'}} src={download}/></div>
      </div>:<><img style={{width:'20px',height:'20px'}} src={download}/></>}
    {/* {type==="feepayment"?<span>Receipt</span>:<></>}<img style={{width:'20px',height:'20px',marginLeft:'5px'}} src={download}/> */}
    </PDFDownloadLink>
  </>
  )

}




export default App1;
