import { Homestyle } from "../Home/Style";
import React,{useState} from "react";
import Footer from "../Home/Footer";
import Navbar from "./Navbar";
import { Container, Button, Grid, Paper, Box ,Typography, Divider } from "@mui/material";
import Cap from "../../Assets/images/cap.svg";
import Insitutte from "../../Assets/images/Insitutte.svg";
import Pay from "../../Assets/images/Pay.svg";
import { Mainpagestyle } from "./Style";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import wallet from "../../Assets/images/wallet.png"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from "../../helpers/axios";

export default function Installments(){
      const [rows, setRows] = useState([]);
      const [payments, setPayments] = useState([]);
      const [updatedRow, setUpdatedRow] = useState([]);
      const [amount,setAmount] = useState(0)
      const [discountedAmount, setDiscountedAmount] = useState(0);
      const [totalAmount, setTotalAmount] = useState(0);

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_INSTITUTE_URL}/Orders/orderId?orderId=${sessionStorage.getItem("orderId")}`)
          .then((res) => {
            console.log("Response", res?.data?.data);
            setRows(res?.data?.data)
            const installments = res?.data?.data?.installment || [];
            const filteredInstallments = installments.filter(item => item !== "0");
            setPayments(filteredInstallments);
          })
          .catch((e) => {
            console.log("Error", e);
          });
       
      }, []);
    const [dueAmount,setDueAmount] = useState(0)
    const [checkedCount,setCheckedCount] = useState(0)
    const [selectedItems, setSelectedItems] = useState([]);
    const [item,setItem] = useState([])
  
      const handleCheckboxClick = (array, index) => {
        console.log("index", array, index);
        const checkbox = document.getElementById(`check${index}`);
        const isChecked = checkbox.checked;
    
        if (!isChecked) {
          // Unchecking: uncheck all checkboxes up to the current index
          for (let i = index; i >= 0; i--) {
            const currentCheckbox = document.getElementById(`check${i}`);
            if (currentCheckbox) {
              currentCheckbox.checked = false;
            }
          }
          setSelectedItems([]);
          setDueAmount(0);
          setCheckedCount(0);
        } else {
          // Checking: check all checkboxes up to the current index
          let newSelectedItems = [];
          let newDueAmount = 0;
          for (let i = 0; i <= index; i++) {
            const currentCheckbox = document.getElementById(`check${i}`);
            if (currentCheckbox) {
              currentCheckbox.checked = true;
              newSelectedItems.push(i);
              newDueAmount += Number(array[i]);
            }
          }
          setSelectedItems(newSelectedItems);
          setDueAmount(newDueAmount);
          setCheckedCount(index + 1);
        }
      };
      function formatNumberWithCommas(number) {
        return number.toLocaleString('en-IN'); 
      }
const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    console.log("clicked")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    const [openCharges, setOpenCharges] = useState(false);
      const [cardCharges,setCardCharges] = useState(null)
      const [upiCharges,setUPICharges] = useState(null)
      const [netBankingcardCharges,setNetBankingCharges] = useState(null)
      const [cardAmount,setCardAmount] = useState(null)
      const [upiAmount,setUPIAmount] = useState(null)
      const [netBankingcardAmount,setNetBankingAmount] = useState(null)
      const [charges,setCharges] = useState(null)
      const [totalDueAmount,setTotalDueAmount] = useState(null)

      const handleCloseCharges = () => {
        setOpenCharges(false);
      };
    

      const handleOpenCharges = () => {
        let chargeDueAmount;
        setOpenCharges(true);
        if (dueAmount <= 100000) {
            const thirtyPercent = (dueAmount * 30) / 100;
            console.log(`30% of ${dueAmount}:`, thirtyPercent);
      
            const pointTwoPercent = (thirtyPercent * 0.2) / 100;
            setCharges(Math.ceil(pointTwoPercent))
            chargeDueAmount = Math.ceil(pointTwoPercent + dueAmount)
            setTotalDueAmount(pointTwoPercent + dueAmount)
            console.log(`0.2% of ${thirtyPercent}:`, pointTwoPercent);
        }
        const cashFreeUrl=`/cashfree-pl/charges`
            const payload= {   
              amount: chargeDueAmount,
            }
            axiosInstance.post(`${process.env.REACT_APP_INSTITUTE_URL}` + cashFreeUrl,payload)
            .then((res)=>{
              setCardCharges(res?.data?.data?.creditCard?.charge)
              setUPICharges(res?.data?.data?.upi?.charge)
              setNetBankingCharges(res?.data?.data?.netBanking?.charge)
              setCardAmount(res?.data?.data?.creditCard?.total)
              setUPIAmount(res?.data?.data?.upi?.total)
              setNetBankingAmount(res?.data?.data?.netBanking?.total)
                console.log("cashfree",res)
            }).catch((err)=>console.log(err)) 
      };

  const testEaseBuzz=(item)=>{
    // setLoading(true)
    if(rows?.instituteId==="42296028-a2be-4ab7-8162-f2171c642355")
      {
        const urlEaseBuzz=`/easebuzz/payment_link/create-link`
       const payload= {   amount: parseFloat(dueAmount).toFixed(1),
         studentId:rows?.studentId,
         orderId:rows?.orderId,
         productinfo: rows?.paymentName,
         firstname: rows?.studentName,
         phone: rows?.phone,
         branchId:rows?.branchId,
         email: rows?.email,
         paymentId:rows?._id
       }
       axios.post(`${process.env.REACT_APP_INSTITUTE_URL}`+urlEaseBuzz,payload)
       .then((res)=>{
         console.log(res)
          
         window.open(res?.data?.res,"_self")
        //  const loadingTimeout = setTimeout(() => {
        //   setLoading(false);
        // }, 800);
       })
       .catch((err)=>{
         console.log(err)
       })
      
      }
    else
      {
        const cashFreeUrl=`/cashfree-pl/create`
        const installment = `installment_${selectedItems.join("_")}`;
        const payload= {   
          amount: parseFloat(totalDueAmount).toFixed(1),
          studentId:rows?.studentId,
          orderId:rows?.orderId,
          productinfo: rows?.paymentName,
          firstname: rows?.studentName,
          phone: rows?.phone,
          branchId:rows?.branchId,
          email: rows?.email,
          paymentId:rows?._id,
          installment:installment,
          channelId:1
        }
        axiosInstance.post(`${process.env.REACT_APP_INSTITUTE_URL}` + cashFreeUrl,payload)
        .then((res)=>{
          window.open(res?.data?.data,"_self")
        //   const loadingTimeout = setTimeout(() => {
        //     setLoading(false);
        //   }, 800);
            console.log("cashfree",res)
        }).catch((err)=>console.log(err))   
   
     
      }
    }

      console.log(selectedItems,dueAmount,"isguhoi")
    return(
        <Mainpagestyle> 
            <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"  style={{ position: 'absolute', top: '-60%', left: '0%' }}
      >
        <DialogTitle id="alert-dialog-title" color='red'>
          {"Alert !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color='red'>
            Please Select the Amount by clicking checkbox to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus style={{color:'red',justifyContent:'flex-end',backgroundColor:'red',width:'1rem',color:'white'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCharges} onClose={handleCloseCharges} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="h6" style={{fontWeight:'bold'}}>Fee Summary</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography style={{margin:"1em 1em 1em 0.3em",fontWeight: 'bold',color:'#737373'}}>
            UPI
          </Typography>
          <div style={{ marginTop: '0.2em', display: 'flex', flexDirection: 'column', gap: '0.5em',border: "1px solid #F9D8D6" ,padding:'1em',boxShadow: "0px 2px 2px 0px #0000001A",borderRadius:'10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <Typography style={{ fontWeight: 'bold' }}>Fee:</Typography>
              <Typography>₹{dueAmount}</Typography>
            </div>
             <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Typography style={{ fontWeight: 'bold' }}>Charges:</Typography>
                <Typography>₹{charges}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Convenience Fee:</Typography>
              <Typography>₹{upiCharges}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Total:</Typography>
              <Typography>₹{upiAmount}</Typography>
            </div>
          </div>
          <Typography style={{margin:"1em 1em 1em 0.3em",fontWeight: 'bold',color:'#737373'}}>
            DEBIT / CREDIT CARD
          </Typography>
          <div style={{ marginTop: '0.2em', display: 'flex', flexDirection: 'column', gap: '0.5em',border: "1px solid #F9D8D6",padding:'1em',boxShadow: "0px 2px 2px 0px #0000001A",borderRadius:'10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Fee:</Typography>
              <Typography>₹{dueAmount}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Typography style={{ fontWeight: 'bold' }}>Charges:</Typography>
                <Typography>₹{charges}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Convenience Fee:</Typography>
              <Typography>₹{cardCharges}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Total:</Typography>
              <Typography>₹{cardAmount}</Typography>
            </div>
          </div>
          <Typography style={{margin:"1em 1em 1em 0.3em",fontWeight: 'bold',color:'#737373'}}>
           NET BANKING
          </Typography>
          <div style={{ marginTop: '0.2em', display: 'flex', flexDirection: 'column', gap: '0.5em',border: "1px solid #F9D8D6",padding:'1em',boxShadow: "0px 2px 2px 0px #0000001A",borderRadius:'10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Fee:</Typography>
              <Typography>₹{dueAmount}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Typography style={{ fontWeight: 'bold' }}>Charges:</Typography>
                <Typography>₹{charges}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Convenience Fee:</Typography>
              <Typography>₹{netBankingcardCharges}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 'bold' }}>Total:</Typography>
              <Typography>₹{netBankingcardAmount}</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{margin:'1em'}}>
        <Button onClick={()=>testEaseBuzz()} style={{backgroundColor:'#D32028',color:'white',width:'10em'}}>
            PAY NOW
          </Button>
          <Button onClick={handleCloseCharges} style={{backgroundColor:'gray',color:'white',width:'10em'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
       <div className="home-bg ">
             <Navbar />
             <Homestyle>
                <Container>
                    <Paper className="paper-setting">
                        <Box my={5}>
                        <Grid container>
                            <Grid item xs={6} sm={4} md={3}>
                                <Typography variant="body2" component="div" className="payment">
                                <span style={{fontFamily:'Outfit-Medium',fontSize:'1.3em',whiteSpace:'nowrap',marginRight:'1em'}}>Installements</span> 

                                <svg
                                    width="5"
                                    height="23"
                                    viewBox="0 0 5 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                    x="0.461426"
                                    y="0.772095"
                                    width="4.17139"
                                    height="21.7188"
                                    rx="2.08569"
                                    fill="#D32028"
                                    />
                                </svg>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8} md={9}>
                                <Box mt={2} ml={3}>
                                <Divider />
                                </Box>
                            </Grid>
                            </Grid>
                            <div>
              <Grid item xs={12}>
            <Grid container  style={{padding:'0%',display:'flex',alignItems:'center',justifyContent:'center',marginTop:'1em'}}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
              <div style={{borderStyle:'solid',borderColor:'#A5A5A5',borderRadius:'14px',borderWidth:'1px',display:'flex',justifyContent:'space-between',padding:'10px',alignItems:'center'}}>
                <div style={{fontFamily:'Inter-Medium'}}>
                Total
                {/* <span style={{fontFamily:'outfit-medium',fontWeight:'bold',marginLeft:'5px'}}>{checkedCount}</span> */}
                </div>
                <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                <div style={{fontFamily:'Inter-Medium'}}>₹{dueAmount?formatNumberWithCommas(dueAmount):"0"}</div>
                <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                <div style={{backgroundColor:'#CB1F26',borderRadius:'10px',paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',cursor:'pointer',whiteSpace:'nowrap',fontSize:'14px'}} ><span ><img style={{height:'12px',width:'20px',marginRight:'5px'}} src={wallet}/><span style={{fontFamily:'Inter-Medium',color:'white'}}  onClick={checkedCount >=1 ? ()=>handleOpenCharges():handleClickOpen} >Pay now</span></span></div>

              </div>

              </Grid>
             
             </Grid>
            </Grid>
              </div>
              {payments?.map((item,index)=>{
                return(
                        <div style={{display:'flex'}}>
                            <div style={{display:'flex',lineHeight:'1rem',justifyContent:'center',alignItems:'center',marginTop:'-1rem',marginRight:'1rem',flexDirection:'column'}}>
                            <input id={`check${index}`} style={{height:'20px',width:'20px'}} type="checkbox"  onClick={() => handleCheckboxClick(payments,index)}></input>
                            </div>
                            <div className="loan-payment-crd" style={{marginLeft:'1em'}}>
                                <Grid  container  spacing={2} >
                                <Grid item xs={3} sm={6} md={3} lg={3}>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Beneficiary"
                                    >
                                    <svg
                                        width="23"
                                        height="23"
                                        viewBox="0 0 23 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M18.8149 6.31734V9.51771C18.8149 9.70935 18.6616 9.86266 18.47 9.86266C18.2783 9.86266 18.125 9.70935 18.125 9.51771V6.72745L16.3926 7.7508V11.5951C16.3926 13.086 15.718 14.4198 14.6602 15.309V18.6359L22.0613 21.6638C22.2376 21.7366 22.3219 21.9397 22.2491 22.1161C22.1763 22.2924 21.9731 22.3767 21.7968 22.3039L14.1811 19.184C14.0508 19.1303 13.9664 19.0038 13.9664 18.862V15.792C13.2382 16.2136 12.4065 16.4397 11.5441 16.4397C10.6817 16.4397 9.85004 16.2136 9.12181 15.792V18.862C9.12181 19.0038 9.03749 19.1303 8.90717 19.184L1.29144 22.3C1.11513 22.3729 0.911997 22.2885 0.839174 22.1122C0.766351 21.9359 0.850672 21.7328 1.02698 21.66L8.42807 18.6321V15.3052C7.37023 14.416 6.69566 13.0822 6.69566 11.5912V7.74697L3.74825 6.00689C3.52212 5.87274 3.52212 5.54312 3.74825 5.40897L11.3678 0.913126C11.4751 0.847969 11.6131 0.847969 11.7204 0.913126L19.3362 5.41281C19.5623 5.54695 19.5623 5.87657 19.3362 6.01072L18.8149 6.31734ZM6.69949 6.94592V6.75045C6.69949 6.6163 6.77998 6.49365 6.8988 6.43616C8.44341 5.72326 9.99185 5.36681 11.5441 5.36681C13.0964 5.36681 14.6448 5.72326 16.1895 6.43616C16.3121 6.49365 16.3888 6.6163 16.3888 6.75045V6.94592L18.4776 5.71176L11.5441 1.61069L4.60679 5.71176L6.69949 6.94592ZM7.38939 9.86266V11.5951C7.38939 13.8909 9.24829 15.7498 11.5441 15.7498C13.84 15.7498 15.6989 13.8909 15.6989 11.5951V9.86266H7.38939ZM7.38939 6.97275V9.17276H15.6989V6.97275C14.3114 6.36334 12.9278 6.05671 11.5441 6.05671C10.1605 6.05671 8.77686 6.3595 7.38939 6.97275Z"
                                        fill="black"
                                        stroke="black"
                                        stroke-width="0.5"
                                        />
                                    </svg>
                                    Student name
                                    </Typography>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Tuviksh"
                                    >
                                    {rows?.studentName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Program"
                                    >
                                    <Box
                                        component="img"
                                        src={Cap}
                                        className="cap-setting"
                                    />
                                    Program/Class
                                    </Typography>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Class"
                                    >
                                    {rows?.course}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Program"
                                    >
                                    <Box
                                        component="img"
                                        src={Insitutte}
                                        className="cap-setting"
                                    />
                                    Institute/School
                                    </Typography>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Class"
                                    >
                                    {sessionStorage.getItem("instName")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Program"
                                    >
                                    <Box
                                        component="img"
                                        src={Pay}
                                        className="cap-setting"
                                    />
                                    Payable Fee
                                    </Typography>
                                    <Typography
                                    variant="body2"
                                    component="div"
                                    className="Class"
                                    >
                                    {item}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </div>
                         </div>
                )
              })}
                            
                        </Box>
                    </Paper>
                </Container>
             </Homestyle>
             <Footer />
        </div>
        </Mainpagestyle>
    )
}